import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import styled from 'react-emotion'
import Img from 'gatsby-image'
import { TwitterFollowButton, TwitterTimelineEmbed } from 'react-twitter-embed'

import Layout from '../components/layout'
import Paragraph from '../components/paragraph'

const StyledImg = styled(Img)`
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`
const HeroContainer = styled.div`
  padding: 0px 0px;
  overflow: hidden;
  position: relative;
  height: 20vh;
`

const CalloutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  text-align: center;
  z-index: 2;
`

const TagLine = styled.h2`
  color: white;
  margin-bottom: 0;
  font-weight: bold;
  text-shadow: 1px 1px 1px #001529, -1px 1px 1px #001529, 1px -1px 1px #001529,
    -1px -1px 1px #001529, 0px 1px 1px #001529, 0px -1px 1px #001529, -1px 0px 1px #001529,
    1px 0px 1px #001529, 2px 2px 1px #001529, -2px 2px 1px #001529, 2px -2px 1px #001529,
    -2px -2px 1px #001529, 0px 2px 1px #001529, 0px -2px 1px #001529, -2px 0px 1px #001529,
    2px 0px 1px #001529, 1px 2px 1px #001529, -1px 2px 1px #001529, 1px -2px 1px #001529,
    -1px -2px 1px #001529, 2px 1px 1px #001529, -2px 1px 1px #001529, 2px -1px 1px #001529,
    -2px -1px 1px #001529;
`

const MobileContainer = styled('div')`
  margin: 0 auto;

  @media screen and (min-width: 1024px) {
    padding: 50px;
    width: 1024px;
  }

  @media screen and (max-width: 1023px) {
    padding: 20px;
    width: 100%;
  }
`

const SocialContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  @media screen and (max-width: 768px) {
    justify-content: flex-start;
  }
`

const SocialItem = styled('div')`
  flex-basis: 49%;

  @media screen and (max-width: 768px) {
    flex-basis: 100%;
  }
`

const MobileHiddenImageWrapper = styled.span`
  @media screen and (max-width: 410px) {
    display: none;
  }
`

const MobileHiddenPipe = styled.span`
  display: inline-block;

  @media screen and (max-width: 726px) {
    display: none;
  }
`

const DesktopHiddenImageWrapper = styled.span`
  display: none;

  @media screen and (max-width: 410px) {
    display: inline;
  }
`

const TabletHiddenWrapper = styled.div`
  @media screen and (max-width: 1023px) {
    display: none;
  }
`

const IndexPage = ({ data, location }) => (
  <Layout location={location}>
    <HeroContainer>
      <StyledImg style={{ position: 'absolute' }} fluid={data.hero.childImageSharp.fluid} />
      <CalloutContainer>
        <TagLine style={{ fontSize: 36 }}>Analytics You Can Trust</TagLine>
      </CalloutContainer>
    </HeroContainer>
    <div style={{ width: '100%', backgroundColor: 'rgba(0, 21, 41, 1)' }}>
      <MobileContainer style={{ padding: '20px 50px' }}>
        <h1 style={{ color: 'white' }}>Events</h1>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          <div
            style={{
              borderStyle: 'solid',
              borderWidth: '1px',
              borderColor: '#40a9ff',
              borderRadius: '12px',
              marginRight: '20px',
              padding: '2px 2px',
            }}
          >
            <Link to="/events/cwl-champs-2019/leaderboard">
              <MobileHiddenImageWrapper>
                <Img fixed={data.champs.childImageSharp.fixed} />
              </MobileHiddenImageWrapper>
              <DesktopHiddenImageWrapper>
                <Img fixed={data.champsMobile.childImageSharp.fixed} />
              </DesktopHiddenImageWrapper>
            </Link>
          </div>
          <MobileHiddenPipe
            style={{
              width: '1px',
              backgroundColor: '#ffffff',
              marginRight: '20px',
              minHeight: '181px',
              height: '100%',
            }}
          />
          <div
            style={{
              borderStyle: 'solid',
              borderWidth: '1px',
              borderColor: '#40a9ff',
              borderRadius: '12px',
              marginRight: '20px',
              padding: '2px 2px',
            }}
          >
            <Link to="/events/pro-league-finals-2019/leaderboard">
              <MobileHiddenImageWrapper>
                <Img fixed={data.plf.childImageSharp.fixed} />
              </MobileHiddenImageWrapper>
              <DesktopHiddenImageWrapper>
                <Img fixed={data.plfMobile.childImageSharp.fixed} />
              </DesktopHiddenImageWrapper>
            </Link>
          </div>
          <div
            style={{
              borderStyle: 'solid',
              borderWidth: '1px',
              borderColor: '#40a9ff',
              borderRadius: '12px',
              marginRight: '20px',
              padding: '2px 2px',
            }}
          >
            <Link to="/events/cwl-pro-league-2019/leaderboard">
              <MobileHiddenImageWrapper>
                <Img fixed={data.cwl.childImageSharp.fixed} />
              </MobileHiddenImageWrapper>
              <DesktopHiddenImageWrapper>
                <Img fixed={data.cwlMobile.childImageSharp.fixed} />
              </DesktopHiddenImageWrapper>
            </Link>
          </div>
          <TabletHiddenWrapper
            style={{
              borderStyle: 'solid',
              borderWidth: '1px',
              borderColor: '#40a9ff',
              borderRadius: '12px',
              marginRight: '20px',
              padding: '2px 2px',
            }}
          >
            <Link to="/events/cwl-anaheim-2019/leaderboard">
              <MobileHiddenImageWrapper>
                <Img fixed={data.anaheim.childImageSharp.fixed} />
              </MobileHiddenImageWrapper>
              <DesktopHiddenImageWrapper>
                <Img fixed={data.anaheimMobile.childImageSharp.fixed} />
              </DesktopHiddenImageWrapper>
            </Link>
          </TabletHiddenWrapper>
        </div>
      </MobileContainer>
    </div>
    <div>
      <MobileContainer>
        <h1>Recent Articles</h1>
        {data.allMarkdownRemark.edges.slice(0, 3).map(({ node }) => (
          <div key={node.frontmatter.path}>
            <div>{node.frontmatter.date}</div>
            <h3>
              <Link to={`/articles${node.frontmatter.path}`}>{node.frontmatter.title}</Link>
            </h3>
            <Paragraph>{node.excerpt}</Paragraph>
          </div>
        ))}
      </MobileContainer>
      <div style={{ width: '100%', backgroundColor: '#001529' }}>
        <MobileContainer>
          <SocialContainer>
            <SocialItem>
              <h1 style={{ color: 'white' }}>Latest Updates</h1>
              <Paragraph style={{ color: 'white' }}>
                {`Follow us on Twitter`}{' '}
                <a href="https://twitter.com/cod_stats" style={{ color: 'white' }}>
                  @CoD_Stats
                </a>{' '}
                {`if you don't fancy looking through the tables of data and want to see the most
                interesting stats in a simpler form. You can also see the widget below to see the
                kind of things we tweet!`}
              </Paragraph>
              <TwitterFollowButton screenName="CoD_Stats" options={{ size: 'large' }} />
            </SocialItem>
            <SocialItem>
              <TwitterTimelineEmbed
                sourceType="profile"
                screenName="CoD_Stats"
                theme="dark"
                linkColor="#40a9ff"
                borderColor="#40a9ff"
                options={{ height: 400 }}
              />
            </SocialItem>
          </SocialContainer>
        </MobileContainer>
      </div>
    </div>
  </Layout>
)

IndexPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
}

export default IndexPage

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 3840, maxHeight: 2160) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    event: file(relativePath: { eq: "events_bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, maxHeight: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vegas: file(relativePath: { eq: "las_vegas_logo.png" }) {
      childImageSharp {
        fixed(width: 175, height: 175) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    plq: file(relativePath: { eq: "cwl_qualifiers_logo.png" }) {
      childImageSharp {
        fixed(width: 175, height: 175) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    cwl: file(relativePath: { eq: "cwl_pro_league_logo.png" }) {
      childImageSharp {
        fixed(width: 175, height: 175) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fw: file(relativePath: { eq: "fort-worth.png" }) {
      childImageSharp {
        fixed(width: 175, height: 175) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    london: file(relativePath: { eq: "cwl-london.png" }) {
      childImageSharp {
        fixed(width: 175, height: 175) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    anaheim: file(relativePath: { eq: "cwl-anaheim-2019.png" }) {
      childImageSharp {
        fixed(width: 175, height: 175) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    plf: file(relativePath: { eq: "pro_league_finals.png" }) {
      childImageSharp {
        fixed(width: 175, height: 175) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    champs: file(relativePath: { eq: "cwl_champs.png" }) {
      childImageSharp {
        fixed(width: 175, height: 175) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    vegasMobile: file(relativePath: { eq: "las_vegas_logo.png" }) {
      childImageSharp {
        fixed(width: 130, height: 130) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    plqMobile: file(relativePath: { eq: "cwl_qualifiers_logo.png" }) {
      childImageSharp {
        fixed(width: 130, height: 130) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    cwlMobile: file(relativePath: { eq: "cwl_pro_league_logo.png" }) {
      childImageSharp {
        fixed(width: 130, height: 130) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fwMobile: file(relativePath: { eq: "fort-worth.png" }) {
      childImageSharp {
        fixed(width: 130, height: 130) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    londonMobile: file(relativePath: { eq: "cwl-london.png" }) {
      childImageSharp {
        fixed(width: 130, height: 130) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    anaheimMobile: file(relativePath: { eq: "cwl-anaheim-2019.png" }) {
      childImageSharp {
        fixed(width: 130, height: 130) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    plfMobile: file(relativePath: { eq: "pro_league_finals.png" }) {
      childImageSharp {
        fixed(width: 130, height: 130) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    champsMobile: file(relativePath: { eq: "cwl_champs.png" }) {
      childImageSharp {
        fixed(width: 130, height: 130) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 400)
          frontmatter {
            title
            author
            path
            date
          }
        }
      }
    }
  }
`
